.inner {
    min-width: 700px ;
  }
.nameCell {
    display: flex;
    align-items: center;
  }
.information{
    text-align:right;
    margin-top: 40px;
    margin-right:30;
  }

  .search {
    width: 300px;
    flex-grow: 1px;
    height: 42px;
    padding: 2px;
    display: flex;
    align-items: center;
  }
  .searchIcon {
    margin-right: 2px;
    color: black
  }
  .searchInput {
    flex-grow: 1px;
    width: 300px;
  }