.ant-carousel .slick-slide {
  text-align: center;
  height: 200px;
  line-height: 160px;
  background: #f4f6f8;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #000;
}

.ant-carousel .slick-slide img {
  display: inherit;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #000065;
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #000;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}